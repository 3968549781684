import React from 'react';

export interface ColumnDef<T> {
    label: React.ReactNode;
    format: (object:T) => React.ReactNode;
}

export interface TableProps<T> {
    objects: any[];
    columnDefs: ColumnDef<T>[];
}

const Table: React.FC<TableProps<any>> = (props) => {

    const {objects, columnDefs} = props;

    const headers = columnDefs.map((cd,i) => <th key={i}>{cd.label}</th>);

    const headerRow = <thead><tr>{headers}</tr></thead>;

    const objectRows = objects.map(object => (<tr key={object.key}>{columnDefs.map((cd,i) => <td key={i}>{cd.format(object)}</td>)}</tr>));

    return (<table>
        {headerRow}
        <tbody>{objectRows}</tbody>
    </table>);
}

export default Table;