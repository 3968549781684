import React, {useState} from "react";
import Button from "./Button";
import TimeStampText from "./TimeStampText";

export interface EditableProps {
    multiLine?: boolean;
    value: string|undefined;
    setValue: (value: string) => Promise<void>;
    setTime?: (value: number) => void;
}

const Editable: React.FC<EditableProps> = ({multiLine, value, setValue, setTime}) => {

    const [isEditing, setIsEditing] = useState<Boolean>(false);

    const [textAreaValue, setTextAreaValue] = useState<string>("");

    const editingStyle = multiLine ? {
        width: '100%',
        height: '200px'
    } : {
    };

    const content = isEditing ? <textarea
        rows={1}
        style={editingStyle}
        onChange={(event) => setTextAreaValue(event.target.value)}
        defaultValue={value}
    /> : multiLine ? <TimeStampText value={value} setTime={setTime}/> : value;

    function onClick(): void {
        if (isEditing) {
            setValue(textAreaValue).then(() => setIsEditing(false));
        } else {
            setIsEditing(true);
        }
    }

    return (<span>{content}&nbsp;<Button
        onClick={(event) => onClick()}
    >{isEditing ? "Save" : "Edit"}</Button>{isEditing ? <Button onClick={() => setIsEditing(false)}>Cancel</Button> : null}</span>);
}

export default Editable;