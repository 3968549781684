import React from 'react';
import './Component.css';

export interface ContainerProps {
}

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = (props) => {
    return (<div className="Component">{props.children}</div>)
};

export default Container;
