import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {JWT} from '../Auth';
import TopNav from "../basic/TopNav";

export function doAwsLogin(): void {
    document.location =
        'https://family-archive.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=5duh1tun4ndvgsm5lbnrhcebl4&response_type=token&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=https%3A%2F%2Fdirksvideo.link%2F';
}

const Home: React.FC = () => {

    const [cookies, setCookie] = useCookies(['cognito']);

    const {hash} = useLocation();

    const jwt = new JWT(cookies['cognito']);

    if (jwt && !jwt.isValid()) {
        document.cookie = "cognito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    useEffect(() => {
        if (hash) {
            const parts: string[] = hash.split('&');

            parts.forEach(part => {
                const token_start = part.indexOf("id_token=");
                if (token_start !== -1) {
                    const tokenBody = part.substring(token_start + "id_token=".length);
                    setCookie('cognito', tokenBody);
                }
            })

            document.location = '/files';
        }
    }, [hash, setCookie]);

    function doLocalLogin(): void {
        document.location = 'https://family-archive.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=6dvug1qs413v1c8d96f2i77nl0&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000';
    }

    const localLogin = document.location.hostname === 'localhost' ? <button onClick={() => doLocalLogin()}>Login</button> : null;

    const awsLogin = document.location.hostname === 'localhost' ? null : <button onClick={() => doAwsLogin()}>Login</button>;

    return (<div><TopNav page={"home"}/>
        {jwt.isValid() ? "You are logged in as " + jwt.getName() : <div>You need to log in {localLogin}{awsLogin}</div>}
    </div>);
}

export default Home;

