import React from "react";
import Button from "../basic/Button";
import {useCookies} from "react-cookie";
import {JWT} from "../Auth";
import {doAwsLogin} from "./Home";

const Login: React.FC = () => {

    const [cookies, ] = useCookies(['cognito']);

    const jwt = new JWT(cookies['cognito']);

    if (jwt && !jwt.isValid()) {
        // delete cookie if it is not valid
        document.cookie = "cognito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    return (<div>
        <div>Your JWT is {jwt.isValid() ? "Valid" : "Invalid"}</div>
        <Button onClick={doAwsLogin}>Start Login</Button>
    </div>);
}

export default Login;