import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {FileItem, queryFileItem, updateFileItem} from "../Api";
import Container from "../basic/Container";
import {useLocalStorage} from "../hooks/UseLocalStorage";
import Editable from "../basic/Editable";
import TopNav from "../basic/TopNav";

export const Video : React.FC = () => {

    const {key} = useParams();

    const [fileItem, setFileItem] = useState<FileItem>();

    const [callLocal, ] = useLocalStorage("callLocal", false);

    const videoElement = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (key) {
            queryFileItem(callLocal, key).then((value) => {
                    if (value) {
                        setFileItem(value);
                    }
                }
            )
        }
    }, [key, callLocal]);

    const videoStyle = {
        width: '100%',
        height: '80%'
    };

    async function setSummary(newSummary: string): Promise<void> {
        if (fileItem) {
            const newFileItem: FileItem = {...fileItem, summary: newSummary};

            await updateAndReload(newFileItem);
        }
    }

    async function setTitle(newTitle: string): Promise<void> {
        if (fileItem) {
            const newFileItem: FileItem = {...fileItem, title: newTitle};

            await updateAndReload(newFileItem);
        }
    }

    async function setDate(newDate: string): Promise<void> {
        if (fileItem) {
            const newFileItem: FileItem = {...fileItem, date: newDate};

            await updateAndReload(newFileItem);
        }
    }

    async function updateAndReload(newFileItem: FileItem): Promise<void> {
        await updateFileItem(callLocal, newFileItem);

        const updatedFileItem = await queryFileItem(callLocal, newFileItem.key);

        if (updatedFileItem) {
            console.log('aftersafe', updatedFileItem);
            setFileItem(updatedFileItem);
        }
    }

    function setTime(time: number): void {
        if (videoElement.current) {
            videoElement.current.currentTime = time;
        }
    }

    const content = fileItem ? <Container>
        <TopNav page={"video"}/>
        <div><span>Title: <Editable value={fileItem.title} setValue={setTitle}/></span>&nbsp;&nbsp;<span>Date: <Editable
            value={fileItem.date}
            setValue={setDate}/></span></div>
        <div><span>Filename: {fileItem.filename}</span>&nbsp;&nbsp;<span>Size: {fileItem.size}</span></div>
        <div><video ref={videoElement} style={videoStyle} src={fileItem.signedUrl} controls={true} autoPlay={true}/></div>
        <div><Editable multiLine={true} value={fileItem.summary} setValue={setSummary} setTime={setTime}/></div>
    </Container> : null;

    return (<div>
        {content}
    </div>);
}