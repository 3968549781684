import React from 'react';

import './Button.css';

export interface ButtonProps {
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    const {disabled} = props;

    return (<button
        className='Button'
        disabled={disabled || false}
        onClick={(event) => props.onClick(event)}
    >{props.children}</button>)
}

export default Button;