import {ApolloClient, gql, HttpLink, InMemoryCache, NormalizedCacheObject, QueryOptions} from "@apollo/client";
import Cookies from "js-cookie";

function getCognitoCookie(): string | undefined {
    return Cookies.get('cognito');
}

const awsGraphQlFetch = (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
    const headers = init?.headers ? new Headers(init.headers) : new Headers();
    if (!headers.has("Authorization")) {
        headers.set("Authorization", getCognitoCookie() || "not available");
    }

    let callOptions: RequestInit | undefined = init;
    if (!callOptions) {
        callOptions = {};
    }
    callOptions.headers = headers;
    return fetch(input, callOptions);
};

const localClient = new ApolloClient({
    link: new HttpLink({
        uri: 'http://localhost:4000'
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        }
    }
});

const awsClient = new ApolloClient({
    link: new HttpLink({
        uri: 'https://925bw2u6wh.execute-api.ca-central-1.amazonaws.com/prod/',
        fetch: awsGraphQlFetch,
    }),
    cache: new InMemoryCache(),
});

function client(callLocal: boolean): ApolloClient<NormalizedCacheObject> {
    return callLocal ? localClient : awsClient;
}

export async function getSignedUrl(callLocal: boolean): Promise<string> {

    const qqlQuery = gql`query test {
      uploadUrl
    }`;

    const query = {
        query: qqlQuery
    };

    const response = await client(callLocal).query(query);

    return response.data.uploadUrl;
}

export interface MultiPartUpload {
    uploadId: string;
    key: string;
}

export async function initiateMultiPartUpload(callLocal: boolean, file: File): Promise<MultiPartUpload> {
    const qqlQuery = gql`query initiateMultiPartUpload($input: InitiateMultiPartUploadInput! ) {
      initiateMultiPartUpload(input: $input) {
        uploadId
        key
      }
    }`;

    const variables = {
        input: {
            filename: file.name,
            lastModified: file.lastModified.toString()
        }
    }

    const query = {
        query: qqlQuery,
        variables: variables
    };

    const response = await client(callLocal).query(query);

    return response.data.initiateMultiPartUpload as MultiPartUpload;
}

export async function multiPartUploadUrl(
    callLocal: boolean,
    partNumber: number,
    multiPartUpload: MultiPartUpload): Promise<string> {

    const gqlQuery = gql`query multiPartUploadUrl($partNumber: Int!, $multiPartUpload: MultiPartUploadInput!) {
      multiPartUploadUrl(partNumber: $partNumber, multiPartUpload: $multiPartUpload)
    }`;

    const variables = {
        partNumber: partNumber,
        multiPartUpload: {
            uploadId: multiPartUpload.uploadId,
            key: multiPartUpload.key
        }
    }

    const query = {
        query: gqlQuery,
        variables: variables
    }

    const response = await client(callLocal).query(query);

    return response.data.multiPartUploadUrl as string;
}

export interface Part {
    PartNumber: number;
    ETag?: string;
}

export async function completeMultiPartUpload(callLocal: boolean,
                                              multiPartUpload: MultiPartUpload,
                                              parts: Part[]): Promise<string> {
    const gqlQuery = gql`
    query completeMultiPartUpload($input: CompleteMultiPartUploadInput!) {
      completeMultiPartUpload(input: $input)
    }`;

    const partVariable = parts.map(p => {return {
        PartNumber: p.PartNumber,
        ETag: p.ETag
    };});

    const variables = {
        input: {
            parts: partVariable,
            multiPartUpload: {
                uploadId: multiPartUpload.uploadId,
                key: multiPartUpload.key
            }
        }
    }

    console.log('variables', variables);

    const query = {
        query: gqlQuery,
        variables: variables
    }

    const response = await client(callLocal).query(query);

    return response.data.completeMultiPartUpload as string;
}


export interface FileItem {
    key: string;
    filename: string;
    upload: string;
    lastModified: string;
    size: string;
    signedUrl?: string;
    title?: string;
    summary?: string;
    date?: string;
}

export interface FileItemListByUpdate {
    fileItemList: FileItem[];
    next?: string;
}

export interface FileItemListByUpdateResult {
    fileItemListByUpdate: FileItemListByUpdate;
}

export async function fileItemListByUpdate(callLocal: boolean): Promise<FileItemListByUpdateResult> {
    const gqlQuery = gql`
      query fileItemListByUpdate {
          fileItemListByUpdate {
            fileItemList {
              key
              filename
              lastModified
              size
              upload
              title
              summary
              date
              signedUrl
            }
            next
          }
      }  
    `;

    const query = {
        query: gqlQuery
    };

    const response = await client(callLocal).query(query);

    return response.data as FileItemListByUpdateResult;
}

export async function queryFileItem(callLocal: boolean, key: string): Promise<FileItem|null> {
    const gqlQuery = gql`
      query fileItem($key: String!) {
          fileItem(key: $key) {
          key
          filename
          lastModified
          size
          upload
          signedUrl
          title
          summary
          date
        }
      }  
    `;

    const variables = {
        key: key
    };

    const query: QueryOptions = {
        query: gqlQuery,
        variables: variables,
        fetchPolicy: "no-cache"
    }

    const response = await client(callLocal).query(query);

    return response.data.fileItem as FileItem|null;
}

export async function transcode(callLocal: boolean, key: string): Promise<void> {
    const gqlMutation = gql`
        mutation SubmitTranscode($input: SubmitTranscodeInput!) {
            submitTranscode(input: $input)
        }
    `;

    const variables = {
        input: {
            key: key
        }
    };

    const mutation = {
        mutation: gqlMutation,
        variables: variables,
    }

    const response = await client(callLocal).mutate(mutation);

    console.log('response', response);
}

export async function updateFileItem(callLocal: boolean, fileItem: FileItem): Promise<void> {
    const gqlMutation = gql`
        mutation UpdateFileItem($input: UpdateFileItemInput!) {
            updateFileItem(input: $input)
        }
    `;

    const variables = {
        input: {
            key: fileItem.key,
            title: fileItem.title,
            summary: fileItem.summary,
            date: fileItem.date
        }
    };


    const mutation = {
        mutation: gqlMutation,
        variables: variables,
    }

    await client(callLocal).mutate(mutation);
}