import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Test from "./pages/Test";
import Files from "./pages/Files";
import Design from "./doc/Design";
import {Video} from "./pages/Video";
import Upload from "./pages/Upload";
import Login from "./pages/Login";
import Home from "./pages/Home";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/upload" element={<Upload/>}/>
                <Route path="/test" element={<Test/>}/>
                <Route path="/files" element={<Files/>}/>
                <Route path="/design" element={<Design/>}/>
                <Route path="/video/:key" element={<Video/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
