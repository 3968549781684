import React from "react";
import Button from '@mui/material/Button';

export interface TopNavProps {
    page: string
}

const TopNav: React.FC<TopNavProps> = (props) => {

    const item = (link: string, label: string) => {
        return <td><Button variant={"contained"} onClick={() => window.location.href=link}>{label}</Button></td>
    }

    return (
        <table><tbody><tr>
            {item("/files","Files")}
            {item("/upload","Upload")}
            {item("/design","Design")}
            {item("/login","Login")}
            {item("/test","Test")}
        <td>Version 1.7</td>
    </tr></tbody></table>);
}

export default TopNav;