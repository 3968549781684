export class JWT {
    expMs?: number;
    payload?: string;
    name?: string;

    constructor(jwtString: string | null) {
        try {
            if (jwtString && jwtString !== 'null') {
                const parts: string[] = jwtString.split(".");
                this.payload = atob(parts[1]);
                const payloadObject = JSON.parse(this.payload);
                const exp = payloadObject.exp;
                this.expMs = +exp * 1000;
                this.name = payloadObject.name;
            }
        }
        catch (e) {
            // Should never happen
            console.error('Invalid token content: ' + jwtString, e);
        }
    }

    isValid(): boolean {
        return this.expMs ? this.expMs > new Date().getTime() : false;
    }

    getName(): string | undefined {
        return this.name;
    }

    getPayload(): string | undefined {
        return this.payload;
    }
};
