import React from 'react';
import TopNav from "../basic/TopNav";

const Design: React.FC = () => {
    return (<div>
        <TopNav page={"design"}/>
        <h1>Design</h1>
        <p>This application archives original media in their existing format
            and makes them available for playback in modern web friendly format.</p>
        <p>AWS Account: 359511245146</p>
        <p>Primary login: jeroen.j.dirks@gmail.com + Google Authenticator on Phone</p>
        <p>Uploading media is done to S3 bucket: arn:aws:s3:::familyarchivecdkstack-uploaddestinationee0a48a0-1oi7tyuv2ea3j based in ca-central-1</p>
        <p>Media are indexed in DDB table in ca-central-1</p>
        <p>We use AWS Elastic Transcoder to covert files to format usable from the web. This service is not available in ca-central-1 so we use it from us-east-1 instead,</p>
    </div>);
}

export default Design;