import React, {useEffect, useState} from "react";
import {FileItem, fileItemListByUpdate, FileItemListByUpdateResult, transcode} from "../Api";
import {useLocalStorage} from "../hooks/UseLocalStorage";
import Table, {ColumnDef} from "../basic/Table";
import Button from "../basic/Button";
import TopNav from "../basic/TopNav";

function smartCompareDate(i1: FileItem, i2: FileItem): number {
    if (i1.date && i2.date) {
        return i1.date.localeCompare(i2.date);
    }
    if (i1.date) {
        return 1;
    }
    if (i2.date) {
        return -1;
    }
    return (i1.upload.localeCompare(i2.upload));
}

function sortFileItemsByDate(fileItems: FileItem[] | undefined) : FileItem[] {
    if (fileItems) {
        const newArray = [...fileItems];
        return newArray.sort((i1, i2) => {
            return smartCompareDate(i1, i2)
        })
    }
    return [];
}

export const Files: React.FC = () => {

    const [callLocal, ] = useLocalStorage("callLocal", false);

    const [result, setResult] = useState<FileItemListByUpdateResult>();

    useEffect(() => {
        fileItemListByUpdate(callLocal).then(result => setResult(
            result));
    }, [callLocal])

    function startTranscode(fileItem: FileItem) {
        console.log("Transcode: ", fileItem);
        transcode(callLocal, fileItem.key)
            .catch(e => console.error(e));
    }

    const columnDefs: ColumnDef<FileItem>[] = [
        {
            label: "Title or Filename",
            format: (fi: FileItem) => fi.title ? fi.title : fi.filename
        },
        {
            label: "Date",
            format: (fi: FileItem) => fi.date ? fi.date : ""
        },
        {
            label: "Size",
            format: (fi: FileItem) => fi.size
        },
        {
            label: "Upload",
            format: (fi: FileItem) => {
                const date = new Date(+(fi.upload));
                return date.toISOString();
            }
        },
        {
            label: "Video",
            format: (fi: FileItem) => {
                return fi.signedUrl ? <a href={"video/" + fi.key}>Play</a> : <Button onClick={() => startTranscode(fi)}>Transcode</Button>
            }
        }
    ];

    const sortedItems = sortFileItemsByDate(result?.fileItemListByUpdate.fileItemList);

    return (<div>
        <TopNav page={"files"}/>
        Files CallLocal: {JSON.stringify(callLocal)}
        <Table columnDefs={columnDefs} objects={sortedItems}/>
    </div>);
}

export default Files;