import React from 'react';
import {useCookies} from "react-cookie";
import TopNav from "../basic/TopNav";
import {JWT} from "../Auth";
import Button from "../basic/Button";
import {useLocalStorage} from "../hooks/UseLocalStorage";

// Manual Tests Page

const Test: React.FC = () => {

    const [callLocal, setCallLocal] = useLocalStorage("callLocal", false);

    const [cookies, ] = useCookies(['cognito']);

    const jwt = new JWT(cookies['cognito']);

    if (jwt && !jwt.isValid()) {
        // delete cookie if it is not valid
        document.cookie = "cognito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    function doLocalLogin(): void {
        document.location = 'https://family-archive.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=6dvug1qs413v1c8d96f2i77nl0&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000';
    }

    return (
        <div>
            <TopNav page={"test"}/>
            <div>Version 1.5 - Welcome {jwt.getName()}</div>
            <div>Your JWT is {jwt.isValid() ? "Valid" : "Invalid"}</div>
            <Button onClick={doLocalLogin}>Local Login</Button>
            <div>Call local server <input
                type="checkbox"
                defaultChecked={callLocal}
                onChange={() => setCallLocal(!callLocal)}
            /></div>
        </div>
    );
}

export default Test;