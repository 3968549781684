import React from "react";

export interface TimeStampTextProps {
    value?: string;
    setTime?: (time: number) => void;
}

const TimeStampText : React.FC<TimeStampTextProps> = (props) => {

    const {value, setTime} = props;

    const regExp: RegExp = new RegExp(/\[(\d\d?):(\d\d)\]/, 'g');

    let currentPos = 0;
    let parts: JSX.Element[] = [];

    let match : RegExpExecArray | null;
    while ((match = regExp.exec(value!)) != null) {
        let minutes: number = 0;
        let seconds: number = 0;
        match.forEach((v,index) => {
            if (index === 1) {
                minutes = +v;
            }
            if (index === 2) {
                seconds = +v;
            }
        });
        const startTime = (minutes * 60) + seconds;

        const linkStyle = {
            background: "none",
            border: "none",
            color: "blue"
        };

        const link: JSX.Element = (<button style={linkStyle} role={"link"} onClick={() => {if (setTime) {setTime(startTime)}}}>{match[0].toString()}</button>);
        if (match.index > currentPos) {
            parts.push((<span>{value?.substring(currentPos, match.index)}</span>));
            currentPos = match.index + match[0].length;
        }
        currentPos = match.index + match[0].length;
        parts.push(link);
    }
    parts.push(<span>{value?.substring(currentPos, value?.length)}</span>);

    return (<pre>{parts}</pre>);
}

export default TimeStampText;
