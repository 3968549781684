import React, {ChangeEvent, useState} from 'react';
import Container from "../basic/Container";

import './Uploader.css';
import Button from "../basic/Button";
import {completeMultiPartUpload, initiateMultiPartUpload, MultiPartUpload, multiPartUploadUrl, Part} from "../Api";
import {useLocalStorage} from "../hooks/UseLocalStorage";

const CHUNK_SIZE = 10000000;

const Uploader: React.FC = () => {

    const [callLocal, setCallLocal] = useLocalStorage("callLocal", false);

    const [multiPartUpload, setMultiPartUpload] = useState<MultiPartUpload>();
    const [status, setStatus] = useState<string>("Idle");
    const [parts, setParts] = useState<Part[]>();

    const [file, setFile] = useState<File>();
    const [lastEtag, setLastEtag] = useState<string>();
    const [completed, setCompleted] = useState<boolean>(false);

    function onFileChange(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.files && event.target.files.length > 0) {
            const nextFile = event.target.files[0];
            setFile(nextFile);
            setParts([]);
            setStatus("Idle");
            setCompleted(false);
        }
    }

    function preparePartsForFile(): Part[]{
        if (file) {
            const partSize = CHUNK_SIZE;
            let index = 0;
            let partNumber = 1;
            const parts = [];
            while (index < file.size) {
                parts.push({PartNumber: partNumber});
                partNumber += 1;
                index = index + partSize;
            }
            return parts;
        }
        return [];
    }

    async function doPartUpload(parts: Part[], part: Part, theMultiPartUpload: MultiPartUpload): Promise<void> {
        if (file) {
            const signedUploadUrl = await multiPartUploadUrl(callLocal, part.PartNumber, theMultiPartUpload);

            const fileStart = (part.PartNumber - 1) * CHUNK_SIZE;
            const fileSlice = file.slice(fileStart, fileStart + CHUNK_SIZE);

            const response = await fetch(signedUploadUrl, {
                method: "PUT",
                headers: {},
                body: fileSlice
            });

            const etag = response.headers.get('ETag');

            if (etag) {
                part.ETag = etag;
                setParts(parts);
                setLastEtag(etag)
            }
        }
    }

    async function startUpload(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
        if (file) {
            setCompleted(false);
            setStatus("Requesting MultiPartUpload Session from S3");
            try {
                const multiPartUpload = await initiateMultiPartUpload(callLocal, file);
                setMultiPartUpload(multiPartUpload);
                setStatus("Received MultiPartUpload Session");
                const parts = preparePartsForFile();
                setParts(parts);
                parts.forEach(part => doPartUpload(parts, part, multiPartUpload));
            } catch (e: any) {
                setStatus("Failed to initiate MultiPartUploadSession: " + e);
            }
        }
    }

    function percentReady(parts: Part[] | undefined) {
        if (parts && parts.length > 0) {
            let done = 0;
            parts?.forEach(part => {
                if (part.ETag) {
                    done += 1;
                }
            });

            if (done === parts.length && multiPartUpload && !completed) {
                completeMultiPartUpload(callLocal, multiPartUpload, parts)
                    .then(response => {
                        setCompleted(true);
                        setStatus(response);
                    });
            }

            return (100.0 * done) / parts.length;
        }
        return 0;
    }

    const completion = percentReady(parts);

    return (<Container>
        <input className='fileInput' type="file" id="input" onChange={(event) => onFileChange(event)}/>
        <div>Size: {file?.size} bytes</div>
        <div><Button disabled={!file || multiPartUpload !== undefined} onClick={startUpload}>Upload</Button></div>
        <div>Completed: {completion}%</div>
        <div>Status: {status}</div>
    </Container>);
};

export default Uploader;