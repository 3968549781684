import React from 'react';
import Uploader from "./Uploader";
import TopNav from "../basic/TopNav";

const Upload: React.FC = () => {

    return (<div>
        <TopNav page={"upload"}/>
        <Uploader/>
    </div>)
};

export default Upload;